import React from "react";
import { Grid, Row, Col, Panel } from "react-bootstrap";
import { robotoStack } from "../typography";
import SectionHeader from "../landing/section-header";
import { Helmet } from "react-helmet";

const LienSessionsPage = () => {
	return (
		<Grid>
			<Helmet>
				<title>Sessions</title>
			</Helmet>
			<Row>
				<Col>
					<Panel style={{ height: "184px" }}>
						<Row>
							<Grid>
								<SectionHeader>Sessions</SectionHeader>
								<div>
									<Col md={12} style={{ paddingLeft: "0px" }}>
										<table className="table" style={{ border: "none" }}>
											<tbody style={{ border: "none" }}>
												<div style={{ paddingTop: "20px" }} />
												<tr>
													<p
														scope="row"
														style={{
															...robotoStack,
															border: "none",
															color: "#4D4D4D",
															fontSize: "0.875rem"
														}}
													>
														Property auctions result in property ownership. Tax
														sales result in fixed return investments.
													</p>
												</tr>
												<tr>
													<p
														scope="row"
														style={{
															...robotoStack,
															border: "none",
															color: "#4D4D4D",
															fontSize: "0.875rem"
														}}
													>
														<a
															href="mailto:Investors@CivicSource.com"
															style={{ color: "#7133FF" }}
														>
															Book
														</a>
														&nbsp;a one-on-one session.*
													</p>
												</tr>
												<tr>
													<p
														scope="row"
														style={{
															...robotoStack,
															border: "none",
															color: "#4D4D4D",
															fontSize: "0.875rem"
														}}
													>
														*Contacto para español.
													</p>
												</tr>
											</tbody>
										</table>
									</Col>
								</div>
							</Grid>
						</Row>
					</Panel>
				</Col>
			</Row>
		</Grid>
	);
};

export default LienSessionsPage;
